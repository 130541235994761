<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left" v-if="!id">
          Add Admin
        </h4>
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left" v-if="id">
          Edit Admin
        </h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/admin/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link to="/admin/blogs">Admin Users</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Form Admin Users
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="d-flex justify-content-around" v-if="loading">
      <loader-component></loader-component>
    </div>
    <form class="mt-4" @submit.prevent="createAkun">
      <div class="row">
        <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
          <input
            type="text"
            class="form-control b-radius px-4"
            id="name"
            placeholder="Name"
            v-model="form.name"
            required
          />
        </div>
        <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
          <input
            type="email"
            class="form-control b-radius px-4"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Email"
            v-model="form.email"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
          <input
            type="number"
            class="form-control b-radius px-4"
            id="phone"
            aria-describedby="emailHelp"
            placeholder="Phone Number"
            v-model="form.telephone"
            required
          />
        </div>
        <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
          <select
            class="form-control b-radius px-3"
            id="gender"
            v-model="form.gender"
            required
          >
            <option selected disabled>Gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
          <div class="form-group">
            <!-- <label for="exampleFormControlTextarea1">Example textarea</label> -->
            <textarea
              class="form-control b-radius px-3"
              id="address"
              rows="3"
              placeholder="Address "
              v-model="form.address"
              required
            ></textarea>
          </div>
        </div>
        <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
          <input
            type="text"
            class="form-control b-radius px-4"
            id="username"
            aria-describedby="emailHelp"
            placeholder="Username"
            v-model="form.username"
            required
          />
        </div>
      </div>
      <div class="d-flex justify-content-end mt-4" v-if="!loading">
        <button type="submit" class="btn btn-primary px-5">Save</button>
      </div>
      <div class="d-flex justify-content-center mt-4" v-if="loading">
        <loader-component></loader-component>
      </div>
    </form>
  </div>
</template>
<script>
import Vue from "vue";
import LoaderComponent from "../../components/Loader.vue";
export default {
  props: ["id"],
  components: { LoaderComponent },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        email: "",
        telephone: "",
        gender: "",
        address: "",
        role: "finance",
      },
    };
  },

  computed: {
    userLogin: function () {
      return this.$store.getters.getProfileCustomers;
    },
  },

  mounted() {
    console.log(this.id);
    if (this.id) {
      this.getBlog();
    }
  },
  methods: {
    createAkun() {
      Vue.swal({
        title: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          const endpoint = "create-user";
          this.$api.post(endpoint, this.form, (status, data, message) => {
            if (status === 200) {
              this.loading = false;
              console.log(data);
              Vue.swal("Success", "Admin successfully added", "success").then(
                () => {
                  this.$router.push("/admin/admin-users");
                  //   this.getInvoices(this.package_id);
                  //   this.resetForm();
                }
              );
            } else {
              this.loading = false;
              console.log(message);
            }
          });
        }
      });
    },

    submit() {
      if (this.id) {
        this.updateBlog();
      } else {
        this.submitBlog();
      }
    },

    resetForm() {
      (this.form.title = ""),
        (this.form.body = ""),
        (this.form.status = ""),
        (this.form.images = null);
    },

    handleUploadImage() {
      this.form.images = this.$refs.images.files[0];
      console.log(this.form.image);
    },

    toList() {
      this.$router.push("/admin/blogs");
    },

    getBlog() {
      this.loading = true;
      const endpoint = "blog/detail/" + this.id;
      this.$api.get(endpoint, (status, data, message) => {
        console.log(status);
        if (status == 200) {
          this.loading = false;
          this.form = data.blog;
          console.log(data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    updateBlog() {
      Vue.swal({
        title: "Are you sure?",
        text: "Your data will be updated",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Update Banner!",
      }).then((result) => {
        if (result.isConfirmed) {
          let formData = new FormData();
          this.loading = true;
          //   formData.append("id", this.form.id);
          formData.append("title", this.form.title);
          formData.append("subtitle", this.form.subtitle);
          formData.append("image", this.form.image);
          this.$store
            .dispatch("updateBlog", formData, this.id)
            .then((res) => {
              this.loading = false;
              console.log(res);
              this.resetForm();
              Vue.swal(
                res.data.status,
                "Your data is already added",
                "success"
              ).then(() => {
                this.toList();
              });
            })
            .catch((err) => {
              let errorMsg = "";
              this.loading = false;
              Object.keys(err.response.data).forEach(function (key) {
                let errorItem = key.charAt(0).toUpperCase() + key.slice(1);
                let errorCause = "";
                let errorLists = err.response.data[key];
                for (var prop in errorLists) {
                  errorCause += "<li>" + errorLists[prop] + "</li>";
                }
                errorMsg +=
                  "<ul style='list-style: none; text-align: left;' class='text-left'><b>" +
                  errorItem +
                  "</b>" +
                  errorCause +
                  "</ul><br>";
              });

              Vue.swal({
                icon: "error",
                title: "Error!",
                html: errorMsg,
              });
              console.log(err.response.data);
            });
        }
      });
    },
  },
};
</script>
